import React from "react";
import Navigation from './components/Navigation'
import Footer from "./components/Footer"
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactsPage.css'


const ContactsPage = () => {
    return(
        <div> 

            <Navigation/>

            <Footer/>

        </div>
    );
}

export default ContactsPage;